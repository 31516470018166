import { IoDocumentAttach } from "react-icons/io5";
import { MdEmail, MdFacebook } from "react-icons/md";
import {
  RiInstagramFill,
  RiLinkedinFill,
  RiSurveyFill,
  RiTwitterFill,
} from "react-icons/ri";
import { clsTemplate } from "../../utils/className";
import { Site } from "../../utils/constants";
import Texts from "../../utils/texts";
import StayInformedButton from "../StayInformedButton";
import TitleText from "../TitleText";
import "./JoinUpPage.css";

export default function JoinUpPage() {
  return (
    <div className={cls`wrapper`}>
      <TitleText className={cls`title`}>
        <span>{Texts.page_three_title_line_one}</span>
        <span>{Texts.page_three_title_line_two}</span>
      </TitleText>
      <span className={cls`subtitle`}>{Texts.page_three_subtitle}</span>
      <StayInformedButton />
      <ContactMediaLinks />
    </div>
  );
}

/**
 * See https://react-icons.github.io/react-icons
 */
function ContactMediaLinks() {
  return (
    <div className={cls`contact_wrapper`}>
      <a
        className={cls`contact_link`}
        href={`mailto:${Site.contact.email}?subject=${Texts.contact_mail_subject}&body=${Texts.contact_mail_content}`}
        target="_blank"
        title="Send us an email"
        rel="noreferrer"
      >
        <MdEmail />
      </a>
      <a
        className={cls`contact_link`}
        href={Site.contact.fb}
        target="_blank"
        title="Facebook"
        rel="noreferrer"
      >
        <MdFacebook />
      </a>
      <a
        className={cls`contact_link`}
        href={Site.contact.ig}
        target="_blank"
        title="Instagram"
        rel="noreferrer"
      >
        <RiInstagramFill />
      </a>
      <a
        className={cls`contact_link`}
        href={Site.contact.x}
        target="_blank"
        title="Twitter | X"
        rel="noreferrer"
      >
        <RiTwitterFill />
      </a>
      <a
        className={cls`contact_link`}
        href={Site.contact.in}
        target="_blank"
        title="LinkedIn"
        rel="noreferrer"
      >
        <RiLinkedinFill />
      </a>
      <a
        className={cls`contact_link`}
        href={Site.contact.brochure}
        target="_blank"
        title="Brochure"
        rel="noreferrer"
      >
        <IoDocumentAttach />
      </a>
      <a
        className={cls`contact_link`}
        href={Site.contact.survey}
        target="_blank"
        title="Fill out our survey!"
        rel="noreferrer"
      >
        <RiSurveyFill />
      </a>
    </div>
  );
}

const cls = clsTemplate("JoinUpPage");
